.flie-analysis {
    width: 600px;
    margin: 0 auto;
    min-height: calc(100vh - 187px);
}

.flie-analysis .t-upload__dragger {
    width: 600px;
    height: 300px;
}
.flie-analysis .from {
    margin-top: 30px;
}
.flie-analysis .t-upload__dragger-btns .t-upload__dragger-upload-btn  {
    display: none;
}