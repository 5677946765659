.d3-flame-graph rect {
  stroke: #EEEEEE;
  fill-opacity: 1;
}

.d3-flame-graph rect:hover {
  stroke: #474747;
  stroke-width: 0.5;
  cursor: pointer;
}

.d3-flame-graph-label {
  pointer-events: none;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  font-size: 12px;
  font-family: Verdana;
  margin-left: 4px;
  margin-right: 4px;
  line-height: 1.5;
  padding: 0 0 0;
  font-weight: 400;
  color: black;
  text-align: left;
}

.d3-flame-graph .fade {
  opacity: 0.6 !important;
}

.d3-flame-graph .title {
  font-size: 20px;
  font-family: Verdana;
}

.d3-flame-graph-tip {
    background-color: white;
    border: none;
    padding: 5px 10px 5px 10px;
    min-width: 250px;
    text-align: left;
    color: black;
    z-index: 10;
}

.color-bar-container {
    min-width: 668px;
    max-width: 768px;
    padding: 0 10px;
    color: white;
}

.color-bar-layout {
    display: flex;
    align-items: center;
    gap: 16px;
}

.color-bar-label {
    flex-shrink: 0;
    font-size: 14px;
}

.color-bar-removed {
    color: #1a1a1a;
}

.color-bar-added {
    color: #1a1a1a;
}

.color-bar-wrapper {
    flex-grow: 1;
    background-color: #2d2d2d;
    border-radius: 6px;
    overflow: hidden;
}

.color-bar {
    display: flex;
}

.color-block {
    flex: 1;
    height: 24px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 12px;
}
