.drop-flow-block {
    margin-top: 32px;
    padding: 20px;
    padding-bottom: 12px;
    border: 1px solid #eee;
    border-radius: 4px;
    position: relative;
}

.drop-flow-block .title {
    display: inline-block;
    padding: 5px;
    position: absolute;
    top: -17px;
    left: 15px;
    background: white;
    font-size: 14px;
    margin: 0;
    font-weight: 500;
    z-index: 10;
    color: #000;
}