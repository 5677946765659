
.for-formControl .rs-form-control-wrapper {
  width: 100%;
  max-width: 100%;
}

.drop-result-site-page-header {
  border: 1px solid rgb(235, 237, 240);
  top: 5%;
  width: 100%;
  height: 80%;
}

.card-container p {
  margin: 0;
}

.site-tree-search-value {
  color: #f50;
}

.show-module-style {
  background: white;
  width: 100%;
  margin: 0 auto;
}

.drop-user-info-show-module-style {
  background: #f2f2f2;
  width: 100%;
  max-height: 800px;
  min-height: 600px; 
}

.drop-content {
  max-height: 800px;
  min-height: 600px; 
}