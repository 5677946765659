
.dom-tree-node .t-collapse-panel__wrapper .t-collapse-panel__content {
    padding-right: 0;
    padding-top: 0;
    padding-bottom: 0;
}

.dom-tree-node .t-collapse-panel__header {
    padding-top: 10px;
    padding-bottom: 10px;
    border-right: 0;
}

.no-inside-border .t-collapse-panel__header {
    border-right: 0;
}