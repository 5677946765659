.hover-reveal {
    cursor: pointer;
}
.hover-reveal .hidden-text {
    display: none;
    margin-left: 5px;
}
.hover-reveal:hover .hidden-text {
    display: inline;
    color: #C0C0C0;
}
.hover-reveal .hidden-text:hover {
    color: #0000FF;
}

.marked-text {
    color: #0000FF;
}