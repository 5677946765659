.flame-graph-tip-trigger {
  padding: 0px;
  margin: 0px;
  border: 0px;
  display: inline-block;
  position: fixed;
  pointer-events: all;
}

.flame-graph-tip--popup {
  padding: 0px !important;
  border-radius: 0 !important;
  margin: 0 !important;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px !important;
  opacity: 0.95;
}

.flame-graph-tip {
  background-color: rgba(0, 0, 0, 0);
  padding: 0px;
  margin: 0px;
  border: 0px;
  display: inline-block;
  opacity: 0.88;
  pointer-events: all;
}

.flame-graph-tip1 {
  background-color: rgba(0, 0, 0, 0);
  padding: 0px;
  margin: 0px;
  border: 0px;
  display: none;
  position: fixed;
  opacity: 0;
  pointer-events: none;
  z-index: 10;
}

.flame-graph-tip1::after{
  content: "";
  position: absolute;
  bottom: 100%;
  left: 10px;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent transparent white transparent;
  pointer-events: none;
}

.flame-graph-tip tooltip-opt-dropdown {
  border-radius: 0;
}

.flame-graph-tip .header {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  padding: 5px 15px;
  border: black;
  border-width: 0px;
  border-style: solid;
  background-color: aliceblue;
  width: fit-content;
}

.flame-graph-tip .content {
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px;
  padding: 7px 15px 5px;
  background-color: black;
  color: white;
  line-height: 130%;
}

.flame-graph-tip--opt-popup {
  padding: 0px !important;
  border-radius: 0 !important;
  margin: 0 !important;
  box-shadow: rgb(38, 57, 77) 0px 20px 30px -10px !important;
  opacity: 0.95;
}

.flame-graph-tip--opt-popup .list {
  padding: 5px 0px;
}

.flame-graph-tip--opt-popup .listitem {
  padding: 5px 20px !important;
  border-left: solid 3px white;
}

.flame-graph-tip--opt-popup .listitem-content {
  padding: 5px 0px;
  line-height: 130%;
}

.flame-graph-tip--opt-popup .listitem:hover {
  background-color: aliceblue;
  border-color: deepskyblue;
}

.flame-graph-tip .operation-popup {
  padding-top: 5px !important;
  margin: 0 !important;
  border-radius: 0;
}

.flame-graph-tip .func {
  font-weight: bold;
  font-size: x-large;
  line-height: 140%;
}

.flame-graph-tip .attr-group {
  padding: 7px 0px;
}

.flame-graph-tip p {
  margin: 0;
  color: white;
}

.flame-graph-tip .attr {
  margin: 0px;
  padding: 2px 0px;
  font-size: medium;
  font-weight:290;
}

.flame-graph-tip .detail {
  margin: 0px;
  padding: 2px 0px;
  font-size: 110%;
}

.flame-graph-tip--operation-desc {
  font-size: xx-small;
  color: darkslategray;
}

.flame-graph-tip--operation-desc code {
  background: #F0F0F0;
  word-wrap: break-word;
  box-decoration-break: clone;
  padding: .1rem .3rem .2rem;
  border-radius: .2rem;
}

.flame-graph-operation-input-popup-menu {
  height: 100%;
  border-right: solid .5px gray;
  overflow: auto;
  max-height: 500px;
}

.flame-graph-operation-input-popup-listitem {
  padding: 10px 20px !important;
  border-right: solid 0px white;
}

.flame-graph-operation-input-popup-listitem:hover {
  cursor: pointer;
  background-color: aliceblue;
  border-color: deepskyblue;
}

.flame-graph-operation-input-popup {
  padding: 0px !important;
  border-radius: 0 !important;
  margin: 0 !important;
  border-color: #0052d9 !important;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.26) !important;
}

.flame-graph-operation-input-popup-desc {
  padding: 24px 12px;
  width: 300px;
}

code {
  background: #F0F0F0;
  word-wrap: break-word;
  box-decoration-break: clone;
  padding: .1rem .3rem .2rem;
  white-space: pre;
  border-radius: .2rem;
}

.color-box {
  width: 100%;
  height: 100%;
  padding: 0;
  display: flex;
  overflow: hidden;
  border-radius: 5px;
  box-shadow: 0 0 2px rgb(0 0 0 / 10%);
}

.color-box .c {
  flex: 1;
}

.operation-tag-group {
  border-radius: 1px;
  border-width: 1px;
  border-color: black;
}
