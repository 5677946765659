/* body{
    overflow: hidden;
} */
/* .demo-layout-footer{
    margin-top: vh;
} */
.container {
    background-color: black;
    border: 0px solid #e1e4e8;
    font-size: 16px;
    width: 100%;
    /* height: 100%; */
    height: 80vh;
    overflow: auto;
    /* overflow-x: hidden; */
    position: relative;
    /* position: absolute; */
}

.row {
    display: flex;
    flex-direction: row;
    padding: 0px 36px;
    cursor: pointer;
    color: white;
}
.row.header {
    display: flex;
    flex-direction: row;
    padding: 0px 36px;
    cursor: pointer;
    color: white;
}

.row.active {
    background-color: yellow;
    color: black;
}


.hex {
    width: 10%;
    font-weight: 600;
    left: 3%;
    margin-right: 50px;
}


.comment {
    width: 25%;
    font-weight: 600;
    position: relative;
    margin-right: 50px;
}

.percent{
    width: 13%;
    font-weight: 600;
    margin-right: 50px;
}

.addr {
    width: 15%;
    font-weight: 600;
    margin-right: 50px;
}

.opcode {
    width: 15%;
    font-weight: 600;
    /* float: left; */
    margin-right: 50px;
 }

.jumpCode {
    width: 14px;
    font-weight: 600;
    /* float: left; */
}
.hex{
    width: 15%;
    font-weight: 600;
    /* float: left; */
    margin-right: 50px;
}

.operand {
    width: 25%;
    max-width: 50%;
    font-weight: 600;
    margin-right: 50px;
    /* float: left; */
    word-wrap:break-word;
  }
