.swap-button {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    align-self: center;
    transition: all 0.3s ease;
    outline: none;
}

.swap-icon {
    width: 24px;
    height: 24px;
}

.tea-table__box .tea-table__tr--blank-new .tea-action-state.status-tip-fixed {
    width: 100% !important;
}

.tea-overlay {
    z-index: 3001;
}